import React from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import styled from 'styled-components';
import Layout from '../components/Layout';
import contactHeaderImg from '../assets/images/bkg-contact-sub-header.jpg';
import bluesCreameryMap from '../assets/images/blues-creamery-map.png';
import SEO from '../components/SEO';

console.log(process);
const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <SEO title="Contact Us" />
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const ContactHeader = styled.div`
  background: url(${contactHeaderImg});
  background-size: cover;
  background-position: top center;
  min-height: 200px;
  margin-bottom: 40px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  @media (min-width: 1200px) {
    background-position: top -50px center;
  }
  @media (max-width: 680px) {
    margin-bottom: 10px;
  }

  div {
    text-align: left;
    max-width: 1100px;
    width: 100%;
  }

  h3 {
    font-family: BrandFont;
    font-size: 3.5rem;
    margin-bottom: 0px;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: inline-block;
    color: white;
  }
`;

const ContactBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }

  main {
    padding: 0;
  }

  @media (max-width: 680px) {
    display: block;
    form {
      padding: 15px;
      padding-right: 40px;
      border-left: 0;
    }
  }

  label {
    font-weight: bold;
    display: flex;
    font-size: 1.5rem;
    padding-bottom: 0.3rem;
    margin-top: 20px;
  }

  input[type='email'],
  input[type='text'],
  input[type='number'],
  textarea {
    padding: 0.75rem 0.6rem;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 5px;
    display: block;
    width: 100%;
  }

  input::placeholder {
    color: #ccc;
  }

  textarea {
    display: block;
    width: 100%;
    min-height: 100px;
  }

  .error {
    color: red;
    font-size: 1.5rem;
  }

  button {
    margin-top: 20px;
    --cast: none;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 1rem 2rem 1.3rem 2rem;
  }

  .contact-right-section {
    padding-top: 2rem;
    padding-left: 3rem;
    border-left: dashed 1px #ccc;
    margin-left: 3rem;
    @media (max-width: 680px) {
      margin-left: 0;
      padding-left: 15px;
    }
  }
`;
const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

export default function ContactPage() {
  return (
    <>
      <ContactHeader>
        <div className="content-mw-1100">
          <h3>Get in Touch</h3>
        </div>
      </ContactHeader>

      <ContactBody className="content-mw-1100">
        <Formik
          initialValues={{
            email: '',
            password: '',
            Subject: 'Blues Creamery Website Form',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            fetch('/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({
                'form-name': 'blues-creamery-contact',
                ...values,
              }),
            })
              .then(() => {
                alert('Success');
                actions.resetForm();
              })
              .catch(() => {
                alert('Error');
              })
              .finally(() => actions.setSubmitting(false));
          }}
        >
          {({ isSubmitting }) => (
            <Form name="blues-creamery-contact" data-netlify>
              <input type="hidden" name="bot-field" />
              <MyTextInput
                label="First Name"
                name="firstName"
                type="text"
                placeholder="Mary"
              />
              <MyTextInput
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Smith"
              />
              <MyTextInput
                label="Email Address"
                name="email"
                type="email"
                placeholder="thebluescreamery@gmail.com"
              />
              <MyTextArea label="Query" name="query" type="textarea" />
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
        <div className="contact-right-section">
          <img src={bluesCreameryMap} alt="Blues Creamery Map" />

          {/* Special offer details
          <p>
            Adverts here for special offers e.g. mixed cheese selections,
            'selection of your choice' etc
          </p> */}
        </div>
      </ContactBody>
    </>
  );
}
